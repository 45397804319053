<template>
  <div>
    <h1 class="mb-0">Sign in</h1>
    <p>Enter your email address and password to access admin panel.</p>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-jwt-fill" aria-labelled-by="pills-jwt-tab-fill">
          <sign-in1-form formType="jwt" email="admin@demo.com" password="admin123"></sign-in1-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>

<script>
import SignIn1Form from './Forms/SignIn1Form'

export default {
  name: 'SignIn1',
  components: { SignIn1Form },
  data: () => ({}),
  mounted () {

  },
  methods: {

  }
}
</script>
